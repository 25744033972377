import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`چطوری حافظه فعال جلوی کار کردنمون رو می‌گیره`}</h1>
    <blockquote>
      <p parentName="blockquote">{`این نوشته یک نصیحته به خودم! ای کاش می‌شد هر روز بتونم اینو به خودم یادآوری کنم.`}</p>
    </blockquote>
    <p>{`ذهن آدم برای اینکه بتونه پردازشی انجام بده و در نهایت تصمیم‌گیری کنه، نیاز داره یکسری اطلاعات رو بیاره تو
حافظه فعالش `}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. مثل این می‌مونه که مغز هارددیسک داشته باشه و بخواد اطلاعاتش رو بیاره توی
RAM خودش که بتونه باهاش کار کنه.`}</p>
    <p>{`مشکلی که حافظه فعال داره اینه که محدوده. یعنی فقط چند تیکه اطلاعات محدود می‌تونه تو خودش نگه داره.
پس اگه ما این حافظه محدود رو با اطلاعاتی پر کنیم که تصمیم‌گیری و انجام کار، بر اساسشون سخته، احتمالا تمایل به کار کردنمون
کم می‌شه و اونو تعویق`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` می‌ندازیم یا ممکنه احساس استرس و اضطراب کنیم.`}</p>
    <p>{`مثلا به هر دلیلی اومدیم حافظه فعالمون رو پر کردیم با این چند تیکه اطلاعات:`}</p>
    <ul>
      <li parentName="ul">{`من میخوام مهاجرت کنم و باید کارهای مهاجرت مو انجام بدم`}</li>
      <li parentName="ul">{`بخاطر بازار ممکنه هر روز کارمو از دست بدم و هیچ پس اندازی ندارم`}</li>
      <li parentName="ul">{`امروز باید برم خرید کنم و خونه رو تمیز کنم`}</li>
      <li parentName="ul">{`دوست دارم ساز زدنم بهتر بشه`}</li>
    </ul>
    <p>{`مغز آدم به اینا نگاه میکنه و چون اکثرشون انجام شدنی نیستن
(مثلا پس انداز کردن انجام شدنی نیست، ما می‌تونیم بریم در رو باز کنیم ولی برا پس انداز کردن کلی مرحله قبلش ممکنه داشته باشه)
و احساس استرس (نیاز به فوریت) و اضطراب ایجاد میشه. همزمان مکانیزم دفاعی به تعویق انداختن بخاطر این احساسات منفی
ممکنه فعال بشه و باعث بشه هیچ کدوم از این کارا رو نکنیم.`}</p>
    <br />
    <p>{`تو دو جهت می‌تونیم به مغزمون کمک کنیم برای اینکه این احساسات منفی ایجاد نشن و راحت بتونه تصمیم بگیره:`}</p>
    <p><strong parentName="p">{`کارهایی که الان توی حافظه فعالمون وجود دارن رو بشکونیم`}</strong></p>
    <p>{`مثلا می‌خوایم مهاجرت کنیم، برای شکوندش از خودمون بپرسیم آخرین قدم این کار چیه:`}</p>
    <ul>
      <li parentName="ul">{`ساکن شدن توی یک خونه بعد از مهاجرت (قدم قبلیش چیه؟)`}
        <ul parentName="li">
          <li parentName="ul">{`پیدا کردن خونه و داشتن پول کافی برای اجاره خونه (قدم قبلیش چیه؟)`}
            <ul parentName="li">
              <li parentName="ul">{`... (10 مرحله دیگه)`}
                <ul parentName="li">
                  <li parentName="ul">{`ترجمه مدارک برای کارهای ویزا و گذرنامه (مرحله اول)`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>{`همینطوری اینکارو انجام می‌دیم تا به مرحله‌ای برسیم که بتونیم اونو توی همون روز انجام بدیم.`}</p>
    <p><strong parentName="p">{`بریم به هارددیسکمون نگاه کنیم!`}</strong></p>
    <p>{`لزوما همه کارهای قابل انجام اونایی نیستن که الآن تو حافظه فعالمون هست. شاید بهتره بپرسیم بجز اینا چه کارهای دیگه‌ای (که قابل انجام هستن)
وجود داره و موقت جایگزینشون کنیم با اینا. یکی از روش هاش exploit کردن چیزایی هست که همین الان داریم انجام می‌دیم.
مثلا بیایم "دوست دارم ساز زدنم بهتر بشه" رو نگاه کنیم.`}</p>
    <p>{`این خیلی کار سختیه، با خودمون میگیم کلی مرحله داره و ساعت ها تمرین لازمه. بیایم برعکس بهش نگاه کنیم، الان کجاییم؟
مثلا ممکنه با خودمون بگیم: الان می‌تونم فلان آهنگ رو با فلان تمپو بزنم. بعد سعی کنیم در حد یک اپسیلون اونو بهبود بدیم. مثلا می‌تونیم امروز تمپو رو
10 BPM افزایش بدم؟`}</p>
    <p>{`آخرین چیزی هم که باید حواسمون بهش باشه اینه که از بهینگی باید فرار کنیم! معمولا ذهن بهینگی رو بهونه می‌کنه می‌گه این اپسیلون که کمکی نمی‌کنه
بهم. خودتون رو گول بزنید بگید که اینا همش بهونه‌های مغزه :D`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Working Memory: `}<a parentName="li" {...{
            "href": "https://pmc.ncbi.nlm.nih.gov/articles/PMC4207727"
          }}>{`https://pmc.ncbi.nlm.nih.gov/articles/PMC4207727`}</a>{` `}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Procrastination`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      